<template>
<span>

    <form-product-file v-on:atualizar="atualizarProducts" :product="product"></form-product-file>

    <v-container fluid>
        <v-divider class="mb-6"></v-divider>
        <v-row>

            <v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="3" v-for="(arquivo, index) in product.arquivos" :key="index" pa-2>
                <v-card class="text-center">
                    <v-flex justify="center">
                        <a :href="`${base_url}upload/product_file/${arquivo.prod_fi_arquivo}`" target="_blank">
                            <v-img class="img-preview mb-3" :src="`${base_url}upload/pdf.webp`" width="100"></v-img>
                        </a>
                        <p>{{arquivo.prod_fi_nome}}</p>
                    </v-flex>

                    <v-card-actions class="justify-center">
                        <v-btn icon>
                            <v-icon @click.prevent="destroyProductFile(arquivo, index)">highlight_off</v-icon>
                        </v-btn>
                    </v-card-actions>

                </v-card>

            </v-col>

        </v-row>
    </v-container>

</span>
</template>

<script>
import {
    URL_BASE
} from '../../../../config/configs'
import FormProductFileComponent from "./FormProductFileComponent";

export default {
    name: "GaleriaProductFileComponent",
    props: {
        product: {
            require: false,
            type: Object | Array,
            default: () => {
                return {
                    prod_id: '',
                    prod_nome: '',
                }
            },
        },
        require: true,
    },

    data() {
        return {
            productFile: {
                require: false,
                type: Object | Array,
                default: () => {
                    return {
                        prod_fi_id: '',
                        product_id: '',
                        prod_fi_nome: '',
                        prod_fi_arquivo: '',
                        prod_fi_icone: '',
                        prod_fi_imagem: '',
                        prod_fi_clicks: '',
                        prod_fi_status: '',
                    }
                }
            },
            base_url: URL_BASE
        }
    },

    methods: {
        atualizarProducts() {
            this.$emit('atualiza')
        },
        destroyProductFile(item, index) {
            this.$swal({
                title: 'Você tem certeza?',
                text: "Você não poderá reverter isso",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#D32F2F',
                cancelButtonColor: '#1976D2',
                confirmButtonText: 'Sim, Exclua!'
            }).then((result) => {
                if (result.value) {
                    this.$store.dispatch('destroyProductFile', item.prod_fi_id)
                        .then(response => {
                            if (response.data) {
                                this.product.arquivos.splice(index, 1);
                            } else {
                                this.$swal({
                                    position: "center",
                                    icon: "error",
                                    title: 'Opssss!',
                                    text: 'Erro ao excluir dados',
                                    showConfirmButton: true,
                                    timer: 6000
                                });
                            }
                        })
                        .catch(error => {
                            console.log(error)
                            this.$swal({
                                position: "center",
                                icon: "error",
                                title: 'Opssss!',
                                text: 'Algo errado aconteceu!',
                                showConfirmButton: true,
                                timer: 6000
                            });
                        })
                }
            })

        },
    },
    components: {
        formProductFile: FormProductFileComponent,
    }
};
</script>

<style scoped>
.theme--dark.v-sheet {
    background-color: #303030;
    border-color: #303030;
    color: #fff;
    box-shadow: none;
}

.btn-upload,
.btn-salvar {
    margin: 0;
}

/*Also*/
.fileinput-button input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    -ms-filter: 'alpha(opacity=0)';
    filter: 'alpha(opacity=0)';
    font-size: 200px;
    direction: ltr;
    cursor: pointer;
}

.img-preview {
    max-width: 100%;
    margin: 0 auto;
}
</style>
