<template>
<span>

    <v-form ref="form" lazy-validation autocomplete="off">
        <v-container fluid pl-0>

            <v-row justify="center">
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="text-center">
                    <v-text-field v-model="product.prod_fi_nome" placeholder="Nome do Arquivo" outlined></v-text-field>

                    <input type="file" ref="files" multiple style="display:none;" accept="application/pdf" id="files" @change="onFilesChange" />
                    <v-btn color="blue-grey" class="btn-upload white--text" @click="$refs.files.click()">
                        Adicionar Arquivos
                        <v-icon right dark>cloud_upload</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

        </v-container>
    </v-form>

</span>
</template>

<script>
import {
    URL_BASE
} from '../../../../config/configs'

export default {
    created() {
        this.prod_fi_nome = this.product.prod_nome
    },
    props: {
        product: {
            require: false,
            type: Object | Array,
            default: () => {
                return {
                    prod_id: '',
                    prod_nome: '',
                    prod_fi_nome: '',
                }
            },
        },
        require: true,
    },
    name: "FormFileProductComponent",
    data() {
        return {
            attachments: [],
            attachment: '',
            preview: null,
            base_url: URL_BASE,
        }
    },
    methods: {

        onFilesSubmit() {

            const formData = new FormData();

            for (let i = 0; i < this.attachments.length; i++) {
                formData.append('arquivos[]', this.attachments[i]);
            }

            formData.append('prod_id', this.product.prod_id);
            formData.append('prod_nome', this.product.prod_nome);
            formData.append('prod_fi_nome', this.product.prod_fi_nome);

            this.attachments = [];
            document.getElementById('files').value = [];

            this.$store.dispatch('addFilesProductFile', formData)
                .then(() => {
                    this.$swal({
                        position: "center",
                        icon: "success",
                        title: 'Arquivo adicionado com sucesso',
                        showConfirmButton: true,
                        timer: 6000
                    });
                    this.$emit('atualizar');
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Erro',
                        text: 'Algo deu errado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
        onFilesChange(e) {
            let selectedFiles = e.target.files || e.dataTransfer.files;

            if (!selectedFiles.length)
                return;

            for (let i = 0; i < selectedFiles.length; i++) {
                this.attachments.push(selectedFiles[i]);
            }

            this.onFilesSubmit();

        },
    },
    components: {

    }
};
</script>

<style scoped>
.theme--dark.v-sheet {
    background-color: #303030;
    border-color: #303030;
    color: #fff;
    box-shadow: none;
}

.btn-upload,
.btn-salvar {
    margin: 0;
}

/*Also*/
.fileinput-button input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    -ms-filter: 'alpha(opacity=0)';
    filter: 'alpha(opacity=0)';
    font-size: 200px;
    direction: ltr;
    cursor: pointer;
}

.img-preview {
    max-width: 100%;
    height: 500px;
}
</style>
